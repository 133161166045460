import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState ,FormFieldTypes} from '@aws-amplify/ui-components';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  formFields: FormFieldTypes;
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  constructor(private ref: ChangeDetectorRef ,private router: Router) { 
    this.formFields = [
      {
        type: "username",
        label: "Email Address",
        placeholder: "Enter your email",
        required: true,
      },
      {
        type: "password",
        label: "Password",
        placeholder: "Enter your password",
        required: true,
      }
    ];
  }

  ngOnInit() {
    // onAuthUIStateChange((authState, authData) => {
    //   console.log("WOW2UP")
    //   this.authState = authState;
    //   this.user = authData as CognitoUserInterface;
    //   // this.ref.detectChanges();
    //   if (this.authState=== 'signedin' || this.authState === 'signedout') {
    //     console.log("Sign in event , redirecting")
    //     this.router.navigate(['/']);
    // }
    // })

  }
 
  // ngOnDestroy() {
  //   return onAuthUIStateChange;
  // }

}
