import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeSpecialChars'
})
export class DecodeSpecialCharsPipe implements PipeTransform {

  transform(value: string): string {
    {
      let map =
      {
        '&amp;' :'&',
        '&lt;' : '<',
        '&gt;' : '>',
        "&quot;": '"',
        '&#39;' : "'",
        '&#x2F;' : '/',
        '&#x60;' : '`',
        '&#x3D;' :' : '
      };
      return value.replace(/&amp;|&lt;|&gt;|&quot;|&#039;|&#x2F;|&#x60;|&#x3D;/g, function(m) {return map[m];});
  }

}
}