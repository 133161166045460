import { Component, ChangeDetectorRef , OnInit} from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import Auth from '@aws-amplify/auth';
import { ApiCallsService } from '../../service/api-calls.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TooltipOptions } from 'ng2-tooltip-directive';
@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {

  title = 'Re-Use with Authentication';
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  peopleByCountry: any;
  array_result:any;
  products = [];
  array_full_categories=[];
  unique=[];
  minlength: number;
  myOptions: TooltipOptions = {
    'placement': 'top',
    'trigger':'click',
    'hideDelayAfterClick':400 };
  someTooltip;
  jwt_token;
  search_input: string;
  constructor(private ref: ChangeDetectorRef,public dataService: ApiCallsService, private clipboard: Clipboard) {
  
  
}

  ngOnInit() {
    
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    })
 
      var array_full_categories = [];
      var unique = [];


      function onlyUnique(value, index, self) { 
          return self.indexOf(value) === index;
      }

  }



  ngOnDestroy() {
    return onAuthUIStateChange;
    }

  onlyUnique(value, index, self) { 
      return self.indexOf(value) === index;
  }

  copyToBuffer(value_to_copy) {

    console.log("copy",value_to_copy);
    this.clipboard.copy(value_to_copy);
  }
  queryByCategory(tag){
    console.log("Tag: ",tag)

      this.dataService.sendAPIRequestByCategory(this.search_input,tag).subscribe(
        success => {
          
          this.products = success;
          console.log("Succcess",this.products) ;
          // Categories
          this.array_full_categories=[];
          for(var j in this.products) { 
            // console.log( this.products[j])
            
            for(var a in this.products[j]['category']) { 
              this.array_full_categories.push(this.products[j]['category'][a])
            }
           }
        // console.log("array_full_categories:",this.array_full_categories);
           // End Categories
        this.unique = this.array_full_categories.filter( this.onlyUnique );
        // console.log("unique:",this.unique)

        },
        error => {
          console.log("Error",error) ;
        }
      );
   }  

  queryAPIRequest(input){

    console.log("Input",input);
    this.minlength = 2;
    this.search_input=input;
    if (input.length >= this.minlength ) {
      // 
      this.dataService.sendAPIRequest(input).subscribe(
        success => {
          
          this.products = success;
          // console.log("Succcess",this.products) ;
          // Categories
          this.array_full_categories=[];
          for(var j in this.products) { 
            // console.log( this.products[j])
            
            for(var a in this.products[j]['category']) { 
              this.array_full_categories.push(this.products[j]['category'][a])
            }
           }
        // console.log("array_full_categories:",this.array_full_categories);
           // End Categories
        this.unique = this.array_full_categories.filter( this.onlyUnique );
        // console.log("unique:",this.unique)

        },
        error => {
          console.log("Error",error) ;
        }
      );

  }
  else if (input=="" || input.length == 1 ) {
      console.log("=====Empty string =====")

  }

  }

}
