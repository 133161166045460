import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ApiCallsService } from '../../service/api-calls.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import Auth from '@aws-amplify/auth';
@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  peer_request_list:any;
  form: FormGroup;
  user:any = {};
  constructor(public dataService: ApiCallsService,public fb: FormBuilder,private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getUserInfo();
    this.listPeers();
    this.form = this.fb.group({
      email: ['', Validators.required]
  })
  }

  listPeers(){


    Auth.currentSession().then(data => {
  
      // console.log("listUserCommands JWT in Commands", data.getAccessToken().getJwtToken());
      
         // 
         this.dataService.listPeers(data.getAccessToken().getJwtToken()).subscribe(
           success => {   
             console.log("Succcess") ;
             this.peer_request_list=success;
           },
           error => {
             console.log("Error",error) ;
           }
         );
          //  
     });
  }


  submitForm(){
    
    console.log("Form : ",this.form.value);
    Auth.currentSession().then(data => {
      // console.log("Form values",this.form.value);
      // console.log("JWT in Commands", data.getAccessToken().getJwtToken());
      
         // 
         this.dataService.sendPeerRequest(this.form.value,data.getAccessToken().getJwtToken()).subscribe(
           success => {   
             console.log("Succcess");
             this.listPeers();
           },
           error => {
             console.log("Error",error) ;
           }
         );
          //  
     });
}
acceptRequest(id_to_accept){
    
  console.log("Id to accept : ",id_to_accept);
  Auth.currentSession().then(data => {

       this.dataService.acceptUserCommand(id_to_accept,data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess");
           this.listPeers();
         },
         error => {
           console.log("Error",error) ;
         }
       );

   });
}

async getUserInfo() {

  this.user = await Auth.currentUserInfo();
  console.log("Current user : ", this.user.username  )
}

}
