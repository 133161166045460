import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ApiCallsService } from './service/api-calls.service';
import { ReactiveFormsModule } from '@angular/forms';


/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { HttpClientModule }    from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { TooltipModule } from 'ng2-tooltip-directive';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CommandsComponent } from './components/commands/commands.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserCommandsComponent } from './components/user-commands/user-commands.component';
import { DecodeSpecialCharsPipe } from './pipes/decode-special-chars.pipe';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TeamsComponent } from './components/teams/teams.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PeerAcceptedPipe } from './pipes/peer-accepted.pipe';
import { LandingComponent } from './components/landing/landing.component';

/* Configure Amplify resources */
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    CommandsComponent,
    PaymentsComponent,
    ProfileComponent,
    PageNotFoundComponent,
    UserCommandsComponent,
    DecodeSpecialCharsPipe,
    NavBarComponent,
    SignUpComponent,
    TeamsComponent,
    PeerAcceptedPipe,
    LandingComponent
  ],
  imports: [
    AmplifyUIAngularModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ClipboardModule,
    TooltipModule ,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    FormsModule,
    NgSelectModule
  ],
  providers: [ApiCallsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
