<div class="row">


    <!--  -->
<div class="col-4 offset-1 mt-5">

        <h4>You've sent invites to: </h4>
        <ul *ngFor="let outbound of peer_request_list">
            <li  *ngIf="outbound.requester_username == user.username" class='list-group-item'>
                <div class="row">
                    <div class="col-8 text-truncate">{{ outbound.peer_email }}</div> 
                    <div class="col-4">
                        <div *ngIf="outbound.status != 'Accepted'" class="badge badge-danger">{{ outbound.status }}</div>
                        <div *ngIf="outbound.status == 'Accepted'" class="badge badge-success">{{ outbound.status }}</div>
                    </div> 
                </div>
            
               
            </li>
        </ul>
        <h4 class="mt-1">Inbound invites :</h4>
        <ul *ngFor="let inbound of peer_request_list">
            <li  *ngIf="inbound.requester_username != user.username" class='list-group-item'>
                <div class="row">
                    <div class="col-8 text-truncate">{{ inbound.requester_email}}</div> 
                    <div class="col-4">
                        <button  *ngIf="inbound.status != 'Accepted'" type="submit" class="btn btn-primary-custom" (click)="acceptRequest(inbound.id) ">Accept</button>
                        <div *ngIf="inbound.status == 'Accepted'" class="badge badge-success">{{inbound.status }}</div>
                    </div> 
                </div>
                
                
            </li>
        </ul>

</div>

<div class="col-4 offset-1 mt-5">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <!-- 1 -->
            <div class="form-group">
            <label for="form_email">Add Team member</label>
            <input type="email" class="form-control" id="form_email" aria-describedby="emailHelp" placeholder="Enter peer email" formControlName="email" required >
            <label *ngIf="form.controls.email.invalid" class=" alert alert-warning p-1 mt-1">
                Email is required
            </label>
            </div>

            <button type="submit" class="btn btn-primary-custom" [disabled]="form.invalid">Add</button>
        </form>
</div>