import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Auth from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private _router: Router ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log("Test")
    return Auth.currentAuthenticatedUser().then(() => { return true; })
      .catch(() => { 
        console.log("403 Prohibited : Re-direct to Authentication")
        this._router.navigate(['/sign-in']);
        return false;
      });
  }
}