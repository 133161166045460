import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CommandsComponent } from './components/commands/commands.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserCommandsComponent } from './components/user-commands/user-commands.component';
import { AuthGuard } from './auth/auth.guard';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { UnauthGuard } from './auth/unauth.guard';
import { TeamsComponent } from './components/teams/teams.component';
import { LandingComponent } from './components/landing/landing.component';

const routes: Routes = [
  { path: '', component: LandingComponent }, 
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'community-commands', component: CommandsComponent },
  { path: 'own-commands', component: UserCommandsComponent ,canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent ,canActivate: [AuthGuard]  },
  { path: 'teams', component: TeamsComponent ,canActivate: [AuthGuard]  },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
