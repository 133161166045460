<!-- Custom -->
<div class="container-fluid main p-0">
        
  <!-- Header -->

<nav-bar></nav-bar>

  <!-- <nav>

  </nav> -->
  <!-- The routed views render in the <router-outlet>-->
  <router-outlet ></router-outlet>

  </div>
