import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import {Command} from "../model/command.model";


@Injectable({
  providedIn: 'root'
})

@Injectable()

export class ApiCallsService {


  private REST_API_SERVER = "https://87gwcup406.execute-api.us-east-1.amazonaws.com/getByName?id=npm";
  jwt_cognito_token:string;
  constructor(private httpClient: HttpClient) { }

  // public sendGetRequest(){
  //   return this.httpClient.get(this.REST_API_SERVER);
  // }


  sendAPIRequest(query : string): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    // console.log("JWT",my_token);


    return this.httpClient.get<any>(`https://87gwcup406.execute-api.us-east-1.amazonaws.com/getByName?id=${query}`,{ headers: new HttpHeaders(
      {
        
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  sendAPIRequestByCategory(query : string,category : string ): Observable<any> {

    return this.httpClient.get<any>(`https://87gwcup406.execute-api.us-east-1.amazonaws.com/getByNameCategory?id=${query}&category=${category}`,{ headers: new HttpHeaders(
      {
        
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  saveCommandApi(post_body : string,jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    console.log("Body",post_body);

    return this.httpClient.post<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/post/command/`,post_body,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  shareCommandApi(post_body ,jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    console.log("Body",post_body);

    return this.httpClient.post<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/post/share-command`,post_body,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  sendPeerRequest(post_body ,jwt_token): Observable<any> {
      // let formObj = post_body;
      // let serializedForm = JSON.stringify(formObj);
      console.log("sendPeerRequest Body",post_body);
    return this.httpClient.post<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/post/peer-request`,post_body,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  acceptUserCommand(id_to_accept,jwt_token): Observable<any> {


    return this.httpClient.get<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/user/peer-accept?id=${id_to_accept}`,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  getUserCommands(jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    // console.log("JWT",my_token);


    return this.httpClient.get<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/user/commands`,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  listPeers(jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    // console.log("JWT",my_token);


    return this.httpClient.get<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/user/list-peers`,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  deleteUserCommand(id_to_delete,jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    // console.log("JWT",my_token);


    return this.httpClient.get<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/user/delete?id=${id_to_delete}`,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }

  updateUserCommand(id_to_update,content_to_update,jwt_token): Observable<any> {
    // let my_token = Auth.currentSession().getAccessToken().getJwtToken();
    // console.log("JWT",my_token);
// ?id=${id_to_update}&content=${escaped_content}
    let escaped_content=this.escapeHTML(content_to_update)
    console.log("Escaped",escaped_content);
    let post_body ={
      "id": id_to_update,
      "command": escaped_content
    }
    return this.httpClient.post<any>(`https://yqatm2vr7k.execute-api.us-east-1.amazonaws.com/post/update`,post_body,{ headers: new HttpHeaders(
      {
        'Authorization': 'Bearer ' + jwt_token,
         'Content-Type': 'application/json'
       }
            ) }) ;
  }
   escapeHTML(str:any)
  {
      var map =
      {
           '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#39;',
          '/': '&#x2F;',
          '`': '&#x60;',
          '=': '&#x3D;'
  
      };
      return str.replace(/[&<>"'/`=]/g, function(m) {return map[m];}).toString( ) ;
  }
  
  
  decodeHtml(str)
  {
      var map =
      {
        '&amp;' :'&',
        '&lt;' : '<',
        '&gt;' : '>',
        "&quot;": '"',
        '&#39;' : "'",
        '&#x2F;' : '/',
        '&#x60;' : '`',
        '&#x3D;' :' : '
      };
      return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;|&#x2F;|&#x60;|&#x3D;/g, function(m) {return map[m];});
  }

  
}