import { Component, OnInit, OnDestroy,ChangeDetectorRef } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState ,FormFieldTypes} from '@aws-amplify/ui-components';
import { Router ,ActivatedRoute,Params} from '@angular/router';
import { NgZone } from '@angular/core';
import { Auth } from 'aws-amplify';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  user: CognitoUserInterface | null;
  authState: AuthState;
  formFields: FormFieldTypes;
  signInformFields: FormFieldTypes;
  id: number;
  private sub: any;
   map = (message) => {
     console.log("Auth message",message)
    if (/auth.*/i.test(message)) {
        return 'auth';
    }

    return message;
}
  constructor(private ref: ChangeDetectorRef,private router: Router,private ngZone:NgZone,private route: ActivatedRoute) { 
  
    
    this.user =undefined;
    this.formFields = [
      {
        type: "username",
        label: "Email Address",
        placeholder: "Enter your email",
        required: true,
      },
      {
        type: "password",
        label: "Password",
        placeholder: "Enter your password",
        required: true,
      }]


      this.signInformFields = [
        {
          type: "username",
          label: "Email Address",
          placeholder: "Enter your email",
          required: true,
        },
        {
          type: "password",
          label: "Password",
          placeholder: "Enter your password",
          required: true,
        }]
        
  }

  ngOnInit() {
 
    console.log("Entering Sign-in Page");
  
    onAuthUIStateChange((authState, authData) => {
      
      
      // 
      if (authState=== 'signedin' && authData) {
        this.user = authData as CognitoUserInterface;
        this.ref.markForCheck();
        this.ngZone.run(()=>this.router.navigate(['/community-commands']));
    }
    })
  }


 
}
