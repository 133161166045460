import { Component, OnInit } from '@angular/core';
import Auth from '@aws-amplify/auth';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile:any = {};
  email:any ;
  phone_number:any ;
  constructor() { }

  ngOnInit(): void {
    // this.getUserInfo();

    
  }
  async getUserInfo() {
    Auth.currentSession().then(data => console.log("JWT", data.getAccessToken().getJwtToken()));

    this.profile = await Auth.currentUserInfo();
    console.log(
    this.email = this.profile.attributes['email'],
    this.phone_number= this.profile.attributes['phone_number']
    )
  }

}
