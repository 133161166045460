
<div class="row">


    <!--  -->
    <div class="col-6 offset-1 mt-5">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="term">
    </div>
    <ul *ngFor="let command of command_list | filter:term">
        
        <li  class='list-group-item'>
          <div class='row'>
                  <div class='col-2 pl-0'>
                      <i  *ngIf="command.user == user.username" class='ml-2 fa fa-handshake' (click)="toggle(command)"></i>
                      <i tooltip="Copied!"  [options]="myOptions" class='ml-2 fa fa-copy' (click)="copyToBuffer(command.command)"></i>
                      <i *ngIf="command.user == user.username" tooltip="Removed!"  [options]="myOptions" class='ml-2 fa fa-trash' (click)="deleteUserCommand(command.id)"></i>
                  </div>
                  
                  <div  class='col-5 pr-0'>
                      <span *ngIf="!command.canEditCode" id='{{ command.id }}'  class='command'>{{ command.command |decodeSpecialChars }}</span>
                  </div>
                  <div class='col-5 pr-0'>
                      <span class='description'>{{ command.description }}</span>
                  </div>
          </div>
        </li>
        <div *ngIf="command.share_visible">Share to :
          <div *ngIf="!requestDirections">No team members to share</div>
          <ng-select  *ngIf="requestDirections"
          [items]="requestDirections" 
          [multiple]="true"
          bindLabel="to_email"
          bindValue="to_user"
          [(ngModel)]="selectedPeers"
          >

            <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let peers of items">
                  <span class="ng-value-label">{{ peers.to_email }}</span>
                  <span class="ng-value-icon right" (click)="clear(peers)" aria-hidden="true">×</span>
              </div>
          </ng-template>

        </ng-select>
        <button class="btn btn-primary-black mb-1" *ngIf="selectedPeers?.length > 0" (click)="shareCommand(command.id)">Share</button>
          <!-- <ul *ngFor="let peers of peer_request_list">

            <li  *ngIf="peers.requester_username == user.username && peers.peer_username != user.username&& peers.status=='Accepted' " class='btn btn-info mb-1'>
                {{ peers.peer_email }}
              </li>
        </ul> -->
        </div>

      </ul>
      <div *ngIf="command_list?.length ==0">
        You haven't yet added your own commands. <br>
        Please use section <b>on the right </b>to add your own commands
      </div>
    </div>
    <!--  -->
    <div class="col-4 mt-5">

   
      
        <form  class="bg-frame" [formGroup]="form" (ngSubmit)="submitForm()">
          <div class="mb-2" >Save your command</div>
            <!-- 1 -->
            <div class="form-group">
              <input type="email" class="form-control" id="form_command" aria-describedby="emailHelp" placeholder="Enter command" formControlName="command" required >
              <label *ngIf="form.controls.command.invalid && disableTextbox" class=" alert alert-warning p-1 mt-1">
                Command is required
            </label>
            </div>


            

            <div class="form-group">
                <input type="form_description_id" class="form-control" id="form_description" placeholder="Ented a description" formControlName="description" required >
                <label *ngIf="form.controls.description.invalid && disableTextbox" class="alert alert-warning p-1 mt-1">
                  Description is required
              </label>
              </div>


                <button type="submit" class="btn btn-primary-black" [disabled]="form.invalid">Add</button>

            
          </form>

    </div>

</div>
