import { Component, OnInit,ChangeDetectorRef  } from '@angular/core';
import { ApiCallsService } from '../../service/api-calls.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import Auth from '@aws-amplify/auth';
import { TooltipOptions } from 'ng2-tooltip-directive';
import { onAuthUIStateChange, CognitoUserInterface } from '@aws-amplify/ui-components';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-commands',
  templateUrl: './user-commands.component.html',
  styleUrls: ['./user-commands.component.scss']
})
export class UserCommandsComponent implements OnInit {
  user:any = {};
  toDos: string[] =["Todo1","Todo2","Todo3"];
  form: FormGroup;
  command_list:any;
  factor:any;
  share_visible:boolean =false;
  peer_request_list:any;
  post_body:any = {};
  selectedPeers:any ;
  requestDirections:any ;
  myOptions: TooltipOptions = {
    'placement': 'top',
    'trigger':'click',
    'hideDelayAfterClick':400 };
term = '';
disableTextbox =  false;

show_form_warning:boolean =false;
trackByIndex(index: number, obj: any): any {
  return index;
}
  constructor(public dataService: ApiCallsService,public fb: FormBuilder, private clipboard: Clipboard,private ref: ChangeDetectorRef,private router: Router) {


   }

  ngOnInit(): void {
    this.getUserInfo();
    this.listPeers();
    this.listUserCommands();
      this.form = this.fb.group({
        command: ['', Validators.required],
        description: ['', Validators.required]
    })
  }
  // submitForm() {
  //   console.log(this.form.value)
  // }
  sortFunc (a, b) {
    return a.updatedAt - b.updatedAt
  }

  submitForm(){
    console.log("Form data validity: ",this.form.status);
    if (this.form.status =="VALID"){
      
      Auth.currentSession().then(data => {
        // console.log("Form values",this.form.value);
        // console.log("JWT in Commands", data.getAccessToken().getJwtToken());
        
           // 
           this.dataService.saveCommandApi(this.form.value,data.getAccessToken().getJwtToken()).subscribe(
             success => {   
               console.log("Succcess");
               this.listUserCommands();
             },
             error => {
               console.log("Error",error) ;
             }
           );
            //  
       });
    }
    else{
      this.show_form_warning=true;
      console.log("Not valid form, showing warning");
    }

}
copyToBuffer(value_to_copy) {

  console.log("copy",value_to_copy);
  this.clipboard.copy(value_to_copy);
}

deleteUserCommand(to_delete_id){


  Auth.currentSession().then(data => {

    console.log("deleteUserCommand JWT in Commands", data.getAccessToken().getJwtToken());
    
       // 
       this.dataService.deleteUserCommand(to_delete_id,data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess") ;
           this.listUserCommands();
         },
         error => {
           console.log("Error",error) ;
         }
       );
        //  
   });
}

listUserCommands(){


  Auth.currentSession().then(data => {

    // console.log("listUserCommands JWT in Commands", data.getAccessToken().getJwtToken());
    
       // 
       this.dataService.getUserCommands(data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess") ;
           this.command_list=success.sort(this.sortFunc);
         },
         error => {
           console.log("Error",error) ;
         }
       );
        //  
   });
}

updateUserCommand(to_udpate_id,command_to_update){


  Auth.currentSession().then(data => {

    console.log("updateUserCommand JWT in Commands", data.getAccessToken().getJwtToken());
    
       // 
       this.dataService.updateUserCommand(to_udpate_id,command_to_update,data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess") ;
           this.listUserCommands();
         },
         error => {
           console.log("Error",error) ;
         }
       );
        //  
   });
}


shareCommand(to_udpate_id){


  Auth.currentSession().then(data => {
      this.post_body= {
        "command_id" : to_udpate_id,
        "shared_with_users":this.selectedPeers
      }
       // 
       this.dataService.shareCommandApi(this.post_body,data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess") ;
           this.listUserCommands();
         },
         error => {
           console.log("Error",error) ;
         }
       );
        //  
   });
}


// 

// setTitleEdit(item) {
//   item.canEditCode = true;
// }

toggle(item) {
  // CHANGE THE NAME OF THE BUTTON.
  if(item.share_visible) {
    item.share_visible = false;
  } 
  else{
    item.share_visible = true
  }
}
listPeers(){

  Auth.currentSession().then(data => {

    // console.log("listUserCommands JWT in Commands", data.getAccessToken().getJwtToken());
      const current_user=data.getAccessToken().payload.username;
      console.log("listPeers user",current_user)
       this.dataService.listPeers(data.getAccessToken().getJwtToken()).subscribe(
         success => {   
           console.log("Succcess") ;
           this.peer_request_list=success.filter(item => item.status==("Accepted") );
           this.requestDirections =  this.peer_request_list.map(item => {
            if (item.requester_username == current_user){
              let temp_item=item
              temp_item.to_email=item.peer_email;
              temp_item.to_user=item.peer_username;
              return temp_item
            }
            else if(item.requester_username != current_user){
              let temp_item=item
              temp_item.to_email=item.requester_email;
              temp_item.to_user=item.requester_username;
              return temp_item
            }
            else{
              return item

            }
            
        })
        console.log("requestDirections",this.user,this.requestDirections)

         },
         error => {
           console.log("Error",error) ;
         }
       );
        //  
   });
}
async getUserInfo() {

  this.user = await Auth.currentUserInfo();
  console.log("Current user : ", this.user.username  )
}
toggleDisable() {
  console.log("WOW tigger")
  this.disableTextbox = true;
}
}