


  <nav class="navbar navbar-dark bg-dark sticky-top">
    <a class="navbar-brand heading mr-auto" routerLink="/" routerLinkActive="active">RE-USE</a>
    
    <a class="navbar-text mr-auto" routerLink="/" routerLinkActive="active">Home</a>
    <a class="navbar-text mr-auto" routerLink="/community-commands" routerLinkActive="active">Community commands</a>
    <a class="navbar-text mr-auto" routerLink="/own-commands" routerLinkActive="active">Own Commands</a>
    <a class="navbar-text mr-auto" routerLink="/teams" routerLinkActive="active">Teams</a>
    <!-- <a class="navbar-text" routerLink="/profile" routerLinkActive="active">Profile</a>
     -->
    <a *ngIf="!user" class="navbar-text " routerLink="/sign-in" routerLinkActive="active"><amplify-button data-test="sign-in-sign-in-button" ><span>Sign In</span></amplify-button></a>
    <a *ngIf="!user" class="navbar-text ml-1" routerLink="/sign-up" routerLinkActive="active"><amplify-button data-test="sign-in-sign-in-button" ><span>Sign Up</span></amplify-button></a>

    <a *ngIf="user " class="App" class="navbar-text" >
      <amplify-sign-out></amplify-sign-out>
    </a>
      


  </nav>