import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'peerAccepted'
})
export class PeerAcceptedPipe implements PipeTransform {

    transform(items: any[]): any {
        if (!items ) {
          console.log(1)
            return items;
        }
        console.log(2)
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.status==("Accepted") );
    }

}
