
<div class="container-fluid main p-0">

  <div class="body-wrapper mt-5">
      
      <!-- Tags -->
      <div id="commandList" class="row" >
  
        <div class="col-md-2 ">
             <div id="category_list" class="col-12  ">
                <div *ngFor="let uniq of this.unique">
                  <button type="button" class="btn btn-dark ml-1 float-left" (click)="queryByCategory(uniq)">{{uniq}}</button>
                
                </div>
             </div>
  
        </div>
  
  
        <div id="searchContainer" class="search col-md-9">
          <input id="searchInput" class="form-control "  (input)="queryAPIRequest($event.target.value)" type="text" placeholder="Search for commands"  [(ngModel)]="search_input"/>
          <div id ="recommendation_buttons" class="row">
            <div id="recommendations" class="col-12 export d-flex ">
              <div id ="recommendation_buttons" class="row">
                <div class="col-xs-12 col-md-auto">Popular options</div>
                <div class="col-xs-12 col-md-auto"> 
                  <button type="button" class="btn btn-outline-dark" (click)="queryAPIRequest($event.target.innerText)" >npm</button>
                </div>
  
                <div class="col-xs-12 col-md-auto"> 
                  <button type="button" class="btn btn-outline-dark" (click)="queryAPIRequest($event.target.innerText)">aws</button>
                </div>
  
                <div class="col-xs-12 col-md-auto"> 
                  <button type="button" class="btn btn-outline-dark" (click)="queryAPIRequest($event.target.innerText)">git</button>
                </div>
                
                <div class="col-xs-12 col-md-auto"> 
                  <button type="button" class="btn btn-outline-dark" (click)="queryAPIRequest($event.target.innerText)">docker</button>
                </div>
  
                <div class="col-xs-12 col-md-auto"> 
                  <button type="button" class="btn btn-outline-dark" (click)="queryAPIRequest($event.target.innerText)">kubectl</button>
                </div>
                
                
                
                
                
  
              </div>
              
  
              </div>
            </div>
  
            <div class="row">
              <div id="searchContainer" class="search col-md-12">
                <ul id="generated-list" class="list list-group list-group-flush">
  
                  <ul *ngFor="let product of products">
                    <li></li>
  
  
                    <li class='list-group-item'>
                      <div class='row'>
                              <div class='col-2 pl-0'>
                                  <i tooltip="Copied!"  [options]="myOptions" class='ml-2 fa fa-copy' (click)="copyToBuffer(product.command)"></i>
                              </div>
                              <div class='col-5 pr-0'>
                                  <span class='command'>{{ product.command }}</span>
                              </div>
                              <div class='col-5 pr-0'>
                                  <span class='description'>{{ product.description }}</span>
                              </div>
                      </div>
                    </li>
    
                  </ul>
  
                </ul>
  
              </div>
            </div>
  
        </div>
        
        <!-- <button class="btn btn-info">Vlad</button> Load from tags collection -->
        <!-- <span class="sort" data-sort="name">Sort by name</span>
        <span class="sort" data-sort="city">Sort by city</span> -->
  
  
      </div>
  
    </div>
  
    
  </div><!-- /.row -->
  
