<!-- <h1 class= "mr-auto mt-5 "  [ngClass]="{'heading': step=='step1'}" (click)="step='step1'">WE-REUSE</h1> -->

<div class="row">
    
    <div class="col-6 offset-3 mt-5">
        <h2 class= "d-flex justify-content-md-center text-center">Search, Save & Share CLI commands</h2>
        <h4 class= "d-flex justify-content-md-center accent-3">For individual developers & software development teams</h4>
    </div>
</div>


<div class="row">  
    <div class="col-12 d-flex justify-content-md-center  mt-5">
        <iframe src="https://www.youtube.com/embed/H_vIuIineYg" frameborder="0" title="YouTube Promo Link" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="width: 28rem; height: 16rem;"></iframe>
    </div>
</div>


<div class="row">  
    <div class="col-6 d-flex justify-content-md-end  mt-5">
       <a class="btn-rocket" routerLink="/community-commands" routerLinkActive="active"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M505.05 19.1a15.89 15.89 0 0 0-12.2-12.2C460.65 0 435.46 0 410.36 0c-103.2 0-165.1 55.2-211.29 128H94.87A48 48 0 0 0 52 154.49l-49.42 98.8A24 24 0 0 0 24.07 288h103.77l-22.47 22.47a32 32 0 0 0 0 45.25l50.9 50.91a32 32 0 0 0 45.26 0L224 384.16V488a24 24 0 0 0 34.7 21.49l98.7-49.39a47.91 47.91 0 0 0 26.5-42.9V312.79c72.59-46.3 128-108.4 128-211.09.1-25.2.1-50.4-6.85-82.6zM384 168a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"></path></svg>
    Web App</a>
    </div>

    <div class="col-6 d-flex justify-content-md-start  mt-5">
        <a class="btn-rocket" href="https://chrome.google.com/webstore/detail/re-usable/lcdbobhipocfjghjggponckjkbaldmbe" target="_blank"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M505.05 19.1a15.89 15.89 0 0 0-12.2-12.2C460.65 0 435.46 0 410.36 0c-103.2 0-165.1 55.2-211.29 128H94.87A48 48 0 0 0 52 154.49l-49.42 98.8A24 24 0 0 0 24.07 288h103.77l-22.47 22.47a32 32 0 0 0 0 45.25l50.9 50.91a32 32 0 0 0 45.26 0L224 384.16V488a24 24 0 0 0 34.7 21.49l98.7-49.39a47.91 47.91 0 0 0 26.5-42.9V312.79c72.59-46.3 128-108.4 128-211.09.1-25.2.1-50.4-6.85-82.6zM384 168a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"></path></svg>
     Chrome Plugin</a>
     </div>

</div>

<div class="row mt-5">  
    <div class="col-12 d-flex justify-content-md-center ">

        <div class="row ">  
            <div class="col-6">
                 <i class="fas fa-database d-inline"></i>  
                 <div class="ml-1 d-inline">Community commands</div>     
            </div> 

            <div class="col-6">
                <i class="fas fa-save d-inline"></i> 
                <div class="ml-1 d-inline">Own commands</div>
            </div> 
        </div>         

      <div class="row">
        <div class="col-6">
            <i class="fas fa-retweet d-inline"></i>
             <div class="ml-1 d-inline">Sharing</div>
         </div> 
         <div class="col">
            <i class="fas fa-users d-inline"></i>
             <div class=" ml-1 d-inline">Teams</div>
         </div> 
      </div>
    </div>
</div>

<div class="row    mt-5">  
    <div class="col-12 text-center">
        <h1>How it works</h1>
        </div>
    </div>


<div class="row h-blocks  mt-5">  
<div class="col-12 centered-manually ">
    <div class="col-6">
        
            <div class="row "> 
                <div class="col ">
                 <h4 class="d-flex justify-content-end">🔎 Search</h4>
                </div>
             </div>
         
             <div class="row"> 
                <div class="col  "> 
                    <p class="d-flex justify-content-end text-grey">Search  for most popular CLI commands</p>
                    <p class="d-flex justify-content-end text-grey">Copy the command from UI into buffer and paste where u need</p>
                    
                </div>
             </div>
    </div>

    <div class="col-6 d-flex justify-content-start">
        <img src="assets/images/search_we_reuse.gif" alt="Searching in Re-use" width="420" height="218">
    </div>
</div>
</div>





<div class="row  mt-5 h-blocks mt-5">  
    <div class="col-12 centered-manually mt-5">


        <div class="col-6 d-flex justify-content-end">
            <img src="assets/images/add_own_commands_reuse.gif" alt="Searching in Re-use" width="262" height="177">
        </div>


        <div class="col-6">
            
                <div class="row "> 
                    <div class="col ">
                     <h4 class="d-flex justify-content-start">➕ Add own commands</h4>
                    </div>
                 </div>

                 <div class="row"> 
                    <div class="col  "> 
                        <p class="d-flex justify-content-start text-grey">You can add , delete and share your own commands</p>
                        
                    </div>
                 </div>
        </div>
    

    </div>
    </div>



    <div class="row h-blocks  mt-5">  
        <div class="col-12 centered-manually ">
            <div class="col-6">
                
                    <div class="row "> 
                        <div class="col ">
                         <h4 class="d-flex justify-content-end">🏀 Add to Team 🏀</h4>
                        </div>
                     </div>
                 
                     <div class="row"> 
                        <div class="col  "> 
                            <p class="d-flex justify-content-end text-grey">Add other registered user to your team</p>
                        </div>
                     </div>
            </div>
        
            <div class="col-6 d-flex justify-content-start">
                <img src="assets/images/add_team_member_reuse.gif" alt="Searching in Re-use" width="420" height="218">
            </div>
        </div>
        </div>



        <div class="row  mt-5 h-blocks mb-5">  
            <div class="col-12 centered-manually mt-5">
        
        
                <div class="col-6 d-flex justify-content-end">
                    <img src="assets/images/share_command_reuse.gif" alt="Searching in Re-use" width="487" height="217">
                </div>
        
        
                <div class="col-6">
                    
                        <div class="row "> 
                            <div class="col ">
                             <h4 class="d-flex justify-content-start">🚀 Share your commands</h4>
                            </div>
                         </div>
        
                         <div class="row"> 
                            <div class="col  "> 
                                <p class="d-flex justify-content-start text-grey">You can share your commands with peers</p>
                            </div>
                         </div>
                </div>
            
        
            </div>
            </div>

            <!-- Footer -->



            <div class="container-fluid  mt-5 py-3 footer-section-style">

                <!----------- Footer ------------>
                <footer class="footer-bs">
                    <div class="row">

                        <div class="col footer-nav text-center">
                                    <a  class="mr-2" href="#">Contacts</a>
                                    <a  class="mr-2" href="#">Terms & Condition</a>
                                    <a  class="mr-2" href="#">Privacy Policy</a>
                                    <a  class="mr-2" href="https://twitter.com/NodevopsN">Twitter</a>


                        </div>

                    </div>
                </footer>
                <section class="mt-2 pt-4  text-center footer-bt"><p>Designed by <a class="footer-dev-name" href="https://www.linkedin.com/in/vladyslavdidenko/">Vlad Didenko</a></p></section>
            
            </div>


            <!-- Back to top button -->
            <button id="button-bk-top" *ngIf="isShow" (click)="gotoTop()"><i class="fas fa-arrow-up"></i></button>