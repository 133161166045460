<p>Profile</p>

<!-- <div class='row'>

    <div class='col-12 pr-0'>
        <span class='email'>Email : {{ email }}</span>
    </div>
    <div class='col-12 pr-0'>
        <span class='phone_number'>Phone number: {{ phone_number }}</span>
    </div>
</div> -->
