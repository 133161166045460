/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:65420f3f-939e-4d6c-b878-7ca1f4c8a6d9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FcgSr3914",
    "aws_user_pools_web_client_id": "7sptej4a4mii5dfp08ulplc898",
    "oauth": {},
    "aws_content_delivery_bucket": "re-use-prod-cloudfront-reuseenv",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dkl46yfixsrxz.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://49u4ow6jyb.execute-api.us-east-1.amazonaws.com/reuseenv",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
