
    
<amplify-authenticator  initialAuthState="signup" >
    <amplify-sign-up  header-text="Create a new account" slot="sign-up" [formFields]="formFields" class="d-flex justify-content-center mt-5">

    </amplify-sign-up>

</amplify-authenticator>

  


