import { Component, OnInit,OnDestroy ,ChangeDetectorRef } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit,OnDestroy {
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  hide_logo: boolean;
  constructor(private ref: ChangeDetectorRef,private router: Router,private ngZone:NgZone,) { 



  }
  

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event.constructor.name === "NavigationEnd") {
        let route_name = (<any>event).url.split("/").slice(-1)[0];
        console.log("Nav : " , route_name)
        if (route_name ==""){
          this.hide_logo = true;
        }
        else{
          this.hide_logo = false;
        }
      }
    })
    
    onAuthUIStateChange((authState, authData) => {
      
      // console.log("Authstate",authState,authData);
      
      if (authState === 'signedout') {
        
        this.user=null;
        console.log("Sign-out flow");
        this.ref.markForCheck();
        this.ngZone.run(()=>this.router.navigate(['/']));
    }
    else{
      this.user = authData as CognitoUserInterface;
    }
     }
    )


    Auth.currentAuthenticatedUser()
    .then(user => {
        // console.log("currentAuthenticatedUser",user);
        this.user = user as CognitoUserInterface;
      //  this.ref.detectChanges();
    })
    .then(data => console.log("Data",data))
    .catch(err => console.log("Catched error",err));
  }
  ngOnDestroy() {
    return onAuthUIStateChange;
  }
 }
